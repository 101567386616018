import React from 'react';
import styles from './SessionHadEndedMessage.module.scss';

const SessionHadEndedMessage = () => (
  <div className={styles['welcome-introduction']}>
    <h1>Unfortunately, the option to update your profile has expired.</h1>
  </div>
);

export default SessionHadEndedMessage;
