export const UPDATE_GET_CODE = '/update/get-code/:profileUpdateQuery';
export const UPDATE_VERIFY_CODE = '/update/verify-code/:profileUpdateQuery';
export const UPDATE = '/update/:apiKey';
export const DELETE_PROFILE = '/delete-profile/:profileId/:messageId/:faceId';
export const AGREEMENT = '/agreement/:profileId/:messageId/:faceId';
export const PROPOSAL = '/proposal/:profileId/:messageId/:faceId';
export const TERMS_AND_CONDITIONS = '/:sharedKey/:profileId/terms-and-conditions';
export const EMAIL_CONFIRMED = '/:sharedKey/:profileId/email-confirmed/:auditionId/:confirmationId';
export const PREVIEW = '/:sharedKey/:profileId/preview';
export const REGISTER = '/:sharedKey/:profileId/:embed?';
