import * as uploadValidationTypes from '../constants/uploadValidationType';
import { MAX_IMAGE_SIZE, MAX_AUDIO_SIZE, MAX_DOCUMENT_SIZE, MAX_VIDEO_SIZE } from '../constants/fileSize';
import { MAX_IMAGE_COUNT, MAX_VIDEO_COUNT, MAX_AUDIO_COUNT, MAX_DOCUMENT_COUNT } from '../constants/fileCount';

export const validateImage = (files, alreadyUploadedFiles = {}) => {
  const filteredFiles = files.filter((file) => file['type'].split('/')[0] === 'image');
  if (filteredFiles.length + alreadyUploadedFiles.imagesCount > MAX_IMAGE_COUNT) {
    return [];
  }

  if (filteredFiles.some((file) => file.size > MAX_IMAGE_SIZE)) return [];

  return files;
};

export const validateVideo = (files, alreadyUploadedFiles = {}) => {
  const filteredFiles = files.filter((file) => file['type'].split('/')[0] === 'video');
  if (filteredFiles.length + alreadyUploadedFiles.videosCount > MAX_VIDEO_COUNT) {
    return [];
  }

  if (filteredFiles.some((file) => file.size > MAX_VIDEO_SIZE)) return [];

  return files;
};

export const valdiateAudio = (files, alreadyUploadedFiles = {}) => {
  const filteredFiles = files.filter((file) => file['type'].split('/')[0] === 'audio');
  if (filteredFiles.length + alreadyUploadedFiles.audiosCount > MAX_AUDIO_COUNT) {
    return [];
  }

  if (filteredFiles.some((file) => file.size > MAX_AUDIO_SIZE)) return [];

  return files;
};

export const validateDocument = (files, alreadyUploadedFiles = {}) => {
  const filteredFiles = files.filter((file) => {
    const fileType = file['type'].split('/')[0];
    return fileType !== 'image' && fileType !== 'video' && fileType !== 'audio';
  });

  if (filteredFiles.length + alreadyUploadedFiles.docsCount > MAX_DOCUMENT_COUNT) {
    return [];
  }

  if (filteredFiles.some((file) => file.size > MAX_DOCUMENT_SIZE)) return [];

  return files;
};

export const filesUploadValidationTypes = {
  [uploadValidationTypes.IMAGE]: {
    accept: ['image/*'],
    validate: validateImage,
    limitMessage: 'You can upload up to 10 images (up to 15 MB each)'
  },
  [uploadValidationTypes.VIDEO]: {
    accept: ['video/mp4', 'video/*'],
    validate: validateVideo,
    limitMessage: 'You can upload up to 10 video files (up to 1GB each).'
  },
  [uploadValidationTypes.AUDIO]: {
    accept: 'audio/mp3, audio/mpeg',
    validate: valdiateAudio,
    limitMessage: 'You can upload up to 10 MP3 files (up to 20MB each).'
  },
  [uploadValidationTypes.DOCUMENT]: {
    accept: [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'text/plain',
      'application/rtf',
      'application/zip',
      'application/x-tar',
      'application/gzip',
      'application/x-rar-compressed',
      'application/x-7z-compressed',
      'application/x-bzip2',
      'application/x-bzip',
      'application/x-xz',
      'application/x-lzip',
      'application/x-lzma',
      'application/x-compress',
      'application/x-gtar',
      'application/x-zip-compressed',
      'application/x-compressed'
    ],
    validate: validateDocument,
    limitMessage: 'You can upload up to 10 documents (up to 25MB each).'
  }
};
