import { validateImage, validateVideo, valdiateAudio, validateDocument } from './filesUploadValidationTypes';

export const defaultFilesLimit = {
  limitMessage:
    'You can upload up to: 10 images (up to 15 MB each); 10 video files (up to 1GB each); 10 MP3 files (up to 20MB each); 10 documents (up to 25MB each).',
  validate: (files, alreadyUploadedFiles = {}) => {
    if (validateImage(files, alreadyUploadedFiles).length === 0) return [];

    if (validateVideo(files, alreadyUploadedFiles).length === 0) return [];

    if (valdiateAudio(files, alreadyUploadedFiles).length === 0) return [];

    if (validateDocument(files, alreadyUploadedFiles).length === 0) return [];

    return files;
  }
};
