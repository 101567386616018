import { _fetch } from 'utils/fetch';

export const getRegistrationData = (sharedKey, profileId) => {
  return _fetch(`PublicRegistration/${sharedKey}/Profile/${profileId}/Intro`);
};

export const getRegistrationDataPreview = (sharedKey, profileId) => {
  return _fetch(`PublicRegistration/${sharedKey}/Profile/${profileId}/IntroPreview`);
};

export const getRegistrationFormData = (sharedKey, profileId) => {
  return _fetch(
    `PublicRegistration/${sharedKey}/Profile/${profileId}/ForRegister`
  );
};

export const getRegistrationFormDataPreview = (sharedKey, profileId) => {
  return _fetch(
    `PublicRegistration/${sharedKey}/Profile/${profileId}/ForRegisterPreview`
  );
};

export const getEmailConfirmedMessage = (sharedKey, profileId, auditionId, confirmationId) => {
  return _fetch(
    `PublicRegistration/${sharedKey}/Profile/${profileId}/EmailConfirmed/${auditionId}/${confirmationId}`
  );
};


export const getUserAgreement = (sharedKey, profileId) => {
  return _fetch(
    `PublicRegistration/${sharedKey}/Profile/${profileId}/UserAgreement`
  );
};

export const register = (sharedKey, profileId, body) => {
  return _fetch(
    `PublicRegistration/${sharedKey}/Profile/${profileId}/Register`,
    { body, method: 'POST' }
  );
};

export const registerPreview = (sharedKey, profileId) => {
  return _fetch(
    `PublicRegistration/${sharedKey}/Profile/${profileId}/SuccessPreview`
  );
};

export const uploadPhoto = (
  serviceUrl,
  externalToken,
  sharedKey,
  actorId,
  cropEnabled,
  body,
  profileId,
  onUploadProgress
) => {
  return _fetch(
    `${serviceUrl}/api/ExternalRegistration/${sharedKey}/Actor/${actorId}/Images/Crop/${cropEnabled}`,
    {
      body,
      method: 'POST',
      multipart: true,
      external: true,
      externalToken,
      onUploadProgress,
      headers: { 
        DatabaseProfileType: profileId,
        FileName: encodeURI(body.files.name)
      }
    }
  );
};

export const uploadVideo = (sharedKey, profileId, body) => {
  return _fetch(
    `PublicRegistration/${sharedKey}/Profile/${profileId}/AddVideo`,
    { body, method: 'POST' }
  );
};