import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import MenuItem from 'components/MenuItem';
import * as menuItemTypes from 'components/MenuItem/types';
import VideoUpload from 'components/VideoUpload';
import Popup from 'components/Popup';

import './VideoPlayer.scss';
import MediaEditButton from 'components/MediaEditButton';

export const VideoPlayer = ({
  videos = [],
  width = '100%',
  height = '235px',
  onDelete = () => {},
  onEdit = () => {},
  description,
  placeholder
}) => {
  const [stateVideos, setStateVideos] = useState(videos);

  useEffect(() => {
    setStateVideos(videos);
  }, [videos]);

  const onVideoEdit = (video) => {
    video.isEditing = true;
    setStateVideos([...stateVideos]);
  };

  return (
    <div className="video-player">
      <div className="videos-container">
        {stateVideos.map((item) =>
          item.isEditing ? (
            <>
              <p className="video-header">{description}</p>
              <VideoUpload
                name="Videos"
                placeholder={placeholder}
                onChange={(videoUrl) => onEdit(item, videoUrl)}
                videoInput={item.videoUrl}
              />
            </>
          ) : (
            <div className="video-container">
              {item.attachmentId && (
                <div className="video-overlay">
                  <div className="video-overlay-top-row">
                    <Popup
                      trigger={(open) => MediaEditButton({ active: open })}
                      position="left top"
                      arrow={false}
                      className="menu"
                      mouseLeaveDelay={100}
                      contentStyle={{ marginLeft: '15px', marginTop: '15px' }}
                    >
                      <MenuItem onClick={() => onVideoEdit(item)} type={menuItemTypes.EDIT}>
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => onDelete(item.attachmentId)} type={menuItemTypes.DELETE}>
                        Delete
                      </MenuItem>
                    </Popup>
                  </div>
                </div>
              )}
              <div key={item.videoUrl} className="video-container-item">
                <ReactPlayer
                  url={item.videoUrl}
                  config={{ vimeo: { playerOptions: { controls: true } } }}
                  width={width}
                  height={height}
                  playing={false}
                />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

VideoPlayer.propTypes = {
  videos: PropTypes.array,
  width: PropTypes.string,
  height: PropTypes.string
};

export default VideoPlayer;
