import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Label.scss';

export const Label = ({ name, error, children }) => (
  <label
    className={classnames('label', {
      error: error
    })}
    htmlFor={name}
  >
    {children}
  </label>
);

Label.propTypes = {
  name: PropTypes.string,
  children: PropTypes.any,
  error: PropTypes.string
};

export default Label;
