import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Panel from 'components/Panel';
import { CompanyNameContext } from 'components/CompanyNameContext';
import AppLoadingBar from 'components/AppLoadingBar';

import { getEmailConfirmedMessage } from 'actions/registration';
import { GLOBAL_ERROR_MESSAGE } from 'utils/constants';

import './EmailConfirmed.scss';

class EmailConfirmed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      translationData: null
    };
  }

  startLoadingBar = () => {
    setTimeout(() => {
      this.LoadingBar.continuousStart();
    }, 100);
  };

  endLoadingBar = () => {
    setTimeout(() => {
      this.LoadingBar.complete();
    }, 100);
  };

  componentDidMount() {
    const { match } = this.props;

    this.startLoadingBar();

    getEmailConfirmedMessage(
      match.params.sharedKey,
      match.params.profileId,
      match.params.auditionId,
      match.params.confirmationId
    )
      .then(response => {
        this.endLoadingBar();

        if (response && response.status === 200) {
          if (response.payload.Errors && response.payload.Errors.length) {
            var error = response.payload.Errors[0];
            toast.error(error);
          }

          if (response.payload.Successes && response.payload.Successes.length) {
            var success = response.payload.Successes[0];
            toast.success(success);
          }

          this.setState({
            translationData: response.payload.Data
          });
        } else {
          this.endLoadingBar();
          toast.error(GLOBAL_ERROR_MESSAGE);
        }
      })
      .catch(() => {
        this.endLoadingBar();
        toast.error(GLOBAL_ERROR_MESSAGE);
      });
  }

  render() {
    const { translationData } = this.state;

    if (!translationData) {
      return (
        <>
          <AppLoadingBar onRef={ref => (this.LoadingBar = ref)} />
        </>
      );
    }

    return (
      <div className="email-confirmed">
        <CompanyNameContext.Consumer>
          {context =>
            context.companyName !== translationData.CompanyName &&
            context.setCompanyNameAndLogo(translationData.CompanyName, translationData.CompanyLogoUrl)
          }
          {/* {context =>
            context.companyLogo !== translationData.CompanyLogo &&
            context.setCompanyLogo(translationData.CompanyLogoUrl)
          } */}
        </CompanyNameContext.Consumer>
        <Panel>
          <div
            className="server-html"
            dangerouslySetInnerHTML={{
              __html: translationData.MessageText
            }}
          />
        </Panel>
        <AppLoadingBar onRef={ref => (this.LoadingBar = ref)} />
      </div>
    );
  }
}

EmailConfirmed.propTypes = {
  match: PropTypes.object
};

export default EmailConfirmed;
