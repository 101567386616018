import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './ErrorBoundary.scss';

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info });
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      return (
        <div className="error-boundary">
          <div>
            <h1>Oh no, something went wrong!</h1>
            <code dangerouslySetInnerHTML={{ __html: error }}></code>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any
};

export default ErrorBoundary;
