import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Registration from '../containers/Registration';
import TermsAndConditions from '../containers/TermsAndConditions';
import EmailConfirmed from '../containers/EmailConfirmed';
import Empty from '../containers/Empty';
import DeleteProfile from '../containers/Messages/DeleteProfile';
import Agreement from '../containers/Messages/Agreement';
import Proposal from '../containers/Messages/Proposal';
import Updation from 'containers/Updation';
import GetCode from 'containers/GetCode';
import VerifyCode from 'containers/VerifyCode';

import * as routes from './routes';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path={routes.UPDATE_GET_CODE}>
        <GetCode />
      </Route>
      <Route exact path={routes.UPDATE_VERIFY_CODE}>
        <VerifyCode />
      </Route>
      <Route exact path={routes.UPDATE}>
        <Updation />
      </Route>
      <Route exact path={routes.DELETE_PROFILE}>
        <DeleteProfile />
      </Route>
      <Route exact path={routes.AGREEMENT}>
        <Agreement />
      </Route>
      <Route exact path={routes.PROPOSAL}>
        <Proposal />
      </Route>
      <Route exact path={routes.TERMS_AND_CONDITIONS} component={TermsAndConditions} />
      <Route exact path={routes.EMAIL_CONFIRMED} component={EmailConfirmed} />
      <Route exact path={routes.PREVIEW} render={(props) => <Registration {...props} isPreview={true} />} />
      <Route exact path={routes.REGISTER} component={Registration} />
      <Route exact path="" component={Empty} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
