import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import './Registration.scss';
import RegistrationForm from 'containers/RegistrationForm';
import Welcome from 'containers/Welcome';
import { CompanyNameContext } from 'components/CompanyNameContext';

const Registration = (props) => {
  const [conditionAccepted, setConditionAccepted] = useState(null);

  const companyContext = useContext(CompanyNameContext);

  useEffect(() => {
    if (conditionAccepted !== null) {
      window.scrollTo(0, 0);
    }
  }, [conditionAccepted]);

  useEffect(() => {
    companyContext.updateEmbedState(props.match.params.embed === 'embed');
  }, []);

  return (
    <>
      {conditionAccepted ? (
        <RegistrationForm {...props} resetForm={() => setConditionAccepted(false)} />
      ) : (
        <Welcome {...props} acceptConditions={(value) => setConditionAccepted(value)} />
      )}
    </>
  );
};

Registration.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

export default Registration;
