import React, { useRef } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import introImg from 'img/intro.png';

import Panel from 'components/Panel';
import AppLoadingBar from 'components/AppLoadingBar';
import Button from 'components/Button';
import SessionHadEndedMessage from 'components/SessionHadEndedMessage';

import { useCompanyNameAndLogo } from 'hooks/useCompanyNameAndLogo';

import { startLoadingBar, endLoadingBar } from 'utils/loadingBar';

import * as routes from '../../routes/routes';

import { getCode } from 'actions/profileUpdate';

const GetCode = () => {
  const history = useHistory();
  const { profileUpdateQuery } = useParams();
  const loadingBarRef = useRef();

  const { isExpired, isLoading } = useCompanyNameAndLogo(loadingBarRef, decodeURIComponent(profileUpdateQuery));

  const onEnterCodeClick = async () => {
    startLoadingBar(loadingBarRef.current);

    try {
      const response = await getCode(decodeURIComponent(profileUpdateQuery));
      endLoadingBar(loadingBarRef.current);
      toast.success(response.successMessage);
      history.push(
        generatePath(routes.UPDATE_VERIFY_CODE, {
          profileUpdateQuery
        })
      );
    } catch (error) {
      endLoadingBar(loadingBarRef.current);
      toast.error(typeof error === 'string' ? error : error.message);
    }
  };

  return (
    <div className="welcome">
      <Panel>
        <div className="welcome-content">
          {isExpired ? (
            <SessionHadEndedMessage />
          ) : (
            <>
              <div className="welcome-introduction">
                <h1>Two-Factor Authentication</h1>
                <p>Click the button below and we'll send you an email with a code to authenticate your profile.</p>
                {!isLoading && (
                  <div className="welcome-button-container">
                    <Button onClick={onEnterCodeClick}>SEND A CODE</Button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </Panel>
      <AppLoadingBar ref={loadingBarRef} />
    </div>
  );
};

export default GetCode;
