import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';

export const ReCaptcha = ({ onChange, fRef }) => (
  <ReCAPTCHA
    ref={fRef}
    sitekey={global.config[process.env.NODE_ENV].captchaSiteKey}
    onChange={onChange}
    size="invisible"
    // hl="lt"
  />
);

ReCaptcha.propTypes = {
  onChange: PropTypes.func
};

export default ReCaptcha;
