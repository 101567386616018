import axios from 'axios';

let call;

export const loadConfig = () => {
  return fetch('/config.json').then((r) => r.json().then((data) => (window.config = data)));
};

export const _fetch = (
  path,
  {
    body = {},
    method = 'GET',
    multipart = false,
    forceBlob = false,
    cancel = false,
    params = null,
    external = false,
    externalToken = null,
    headers = {},
    json = true,
    onUploadProgress = false
  } = {}
) => {
  if (call && cancel) {
    call.cancel('Only one request allowed at a time.');
  }

  if (!global.config) {
    return loadConfig().then(() => {
      return _fetch(path, {
        body,
        method,
        multipart,
        forceBlob,
        cancel,
        params,
        external,
        externalToken,
        headers
      });
    });
  }

  const API_URL = global.config[process.env.NODE_ENV].apiUrl;
  const request = {
    method: method,
    crossDomain: true,
    validateStatus: (status) => {
      return status < 500;
    }
  };

  if (!multipart && json) {
    request.headers = {
      ...request.headers,
      Pragma: 'no-cache',
      'Content-Type': 'application/json'
    };
  }

  if (request) {
    request.params = params;
    request.headers = {
      ...request.headers,
      ...headers
    };
  }

  if (forceBlob) {
    request.responseType = 'blob';
  }

  if (cancel) {
    call = axios.CancelToken.source();
    request.cancelToken = call.token;
  }

  if (externalToken) {
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${externalToken}`
    };
  }

  if (request && onUploadProgress) {
    request.onUploadProgress = onUploadProgress;
  }

  if (method !== 'GET') {
    if (multipart) {
      const formData = new FormData();
      Object.keys(body).forEach((key) => {
        if (Array.isArray(body[key])) {
          body[key].forEach((item) => {
            formData.append(key, item);
          });
        } else {
          formData.append(key, body[key]);
        }
      });

      request.data = formData;
    } else if (json) {
      request.data = JSON.stringify(body);
    } else {
      request.data = body;
    }
  }

  const url = external ? path : `${API_URL}/${path}`;

  return axios(url, request)
    .then(async (response) => {
      if (response.status === 403) {
        // there is no store, so we are using default browser pushState.
        return (window.location.href = `${process.env.PUBLIC_URL}/`);
      }

      const contentType = response.headers['content-type'];

      if (!contentType) {
        return {
          status: response.status
        };
      }

      if (forceBlob && response.status >= 400) {
        return {
          status: response.status,
          payload: await new Response(response.data).json()
        };
      }

      if (forceBlob) {
        return response.data;
      }

      return {
        status: response.status,
        payload: response.data
      };
    })
    .catch((error) => {
      throw new Error(error);
    });
};
