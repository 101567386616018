import React from 'react';
import Popup from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';

const PopupComponent = ({ children, contentStyle, ...props }) => {
  return (
    <Popup
      keepTooltipInside
      contentStyle={{
        padding: 0,
        zIndex: 1,
        fontFamily: 'Avenir Next Medium',
        ...contentStyle
      }}
      {...props}
    >
      {children}
    </Popup>
  );
};

export default PopupComponent;
