import React from 'react';
import PropTypes from 'prop-types';

import Plus from './Icons/Plus';
import PlusCircleOutline from './Icons/PlusCircleOutline';

import './Icon.scss';

const Icons = {
  Plus,
  PlusCircleOutline
};

function camelize(str) {
  const result = str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

const Icon = ({ icon, size = 30, ...rest }) => {
  const Component = Icons[camelize(icon)];

  if (!Component) {
    throw new Error('No such icon in icons list');
  }

  return (
    <span className="icon" {...rest}>
      <Component width={size} height={size}/>
    </span>
  );
};

Icon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.number
};

export default Icon;
