import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './Button.scss';

export const Button = ({
  className,
  disabled = false,
  error = false,
  danger = false,
  onClick = () => {},
  type = 'button',
  children,
}) => (
  <button
    className={classnames(className, {
      button: true,
      disabled: disabled,
      error: error,
      danger: danger,
    })}
    onClick={onClick}
    disabled={disabled}
    type={type}
  >
    {children}
  </button>
);

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  children: PropTypes.any,
};

export default Button;
