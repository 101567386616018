import React from 'react';
import ImageGallery from 'react-image-gallery';
import PropTypes from 'prop-types';

import VideoPlayer from 'components/VideoPlayer';

import 'react-image-gallery/styles/scss/image-gallery.scss';
import './PhotoSlider.scss';

const Video = ({ item }) => {
  if (!item.videoUrl) {
    return;
  }

  return (
    <div key={`gallery_${item.videoUrl}`}>
      <VideoPlayer videos={[item]} height={null} />
    </div>
  );
};

const PhotoSlider = ({ images, videos }) => {
  const galleryItems = [
    ...images,
    ...videos.reduce((result, video, index) => {
      if (!video.videoUrl) {
        return result;
      }

      result.push({
        ...video,
        renderItem: () => <Video item={video} />,
        original: `${video.videoUrl}_${index}`
      });

      return result;
    }, [])
  ];

  return (
    <div className="photo-slider">
      <ImageGallery
        items={galleryItems}
        showFullscreenButton={false}
        showPlayButton={false}
        showThumbnails={false}
        showIndex={galleryItems.length > 1}
      />
    </div>
  );
};

PhotoSlider.propTypes = {
  images: PropTypes.array,
  videos: PropTypes.array
};

export default React.memo(PhotoSlider, (prevProps, nextProps) => nextProps.images.length !== prevProps.images.length);
