import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Label from 'components/Label';

import './Dropdown.scss';

export const Dropdown = ({
  field,
  name,
  placeholder,
  label,
  onChange = () => {},
  children,
  error,
  additionalControl,
  className
}) => (
  <div className="dropdown-container">
    {label && (
      <Label name={name} error={error}>
        {label}
      </Label>
    )}
    <div className="dropdown-component">
      <select
        id={name}
        className={classnames('dropdown', {
          [className]: className,
          error: error,
        })}
        placeholder={placeholder}
        onChange={onChange}
        {...field}
        value={field && field.value ? field.value : ''}
      >
        <option value=""></option>
        {children}
      </select>
      {!!additionalControl && additionalControl}
    </div>
  </div>
);

Dropdown.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  children: PropTypes.any,
  field: PropTypes.object,
  error: PropTypes.string,
  additionalControl: PropTypes.any,
};

export default Dropdown;
