export const startLoadingBar = (ref) => {
  setTimeout(() => {
    ref.continuousStart();
  }, 100);
};

export const endLoadingBar = (ref) => {
  setTimeout(() => {
    ref?.complete();
  }, 100);
};
