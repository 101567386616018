import React from 'react';
import PropTypes from 'prop-types';

import './Empty.scss';

class Empty extends React.Component {
  render() {
    return (
      <div className="empty">
        <div>
          <h1>Production Tools</h1>
        </div>
      </div>
    );
  }
}

Empty.propTypes = {
  match: PropTypes.object
};

export default Empty;
