import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Label from 'components/Label';

import './Input.scss';

export const Input = ({
  field,
  name,
  placeholder,
  label,
  onChange = () => {},
  value = '',
  error
}) => (
  <div className="input-container">
    {label && <Label name={name} error={error}>{label}</Label>}
    <input
      id={name}
      className={classnames('input', {
        error: error
      })}
      placeholder={placeholder}
      {...field}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
        if (field && field.onChange) {
          field.onChange(event);
        }
      }}
      value={field && field.value ? field.value : value}
    />
    {error && <div className="error-message">{error}</div>}
  </div>
);

Input.propTypes = {
  field: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.string
};

export default Input;
