import React from 'react';
import PropTypes from 'prop-types';
import UICheckbox from '@material-ui/core/Checkbox';
import './Checkbox.scss';
import 'rc-checkbox/assets/index.css';

export const Checkbox = ({ onChange = () => {} }) => (
  <UICheckbox className="checkbox" onChange={onChange} />
);

Checkbox.propTypes = {
  onChange: PropTypes.func
};

export default Checkbox;
