import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import PhotoSlider from 'components/PhotoSlider';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Panel from 'components/Panel';
import AppLoadingBar from 'components/AppLoadingBar';
import { CompanyNameContext } from 'components/CompanyNameContext';

import { startLoadingBar, endLoadingBar } from 'utils/loadingBar';
import { textWithClickableLink } from 'utils/htmlToLink';

import { getRegistrationData, getRegistrationDataPreview } from 'actions/registration';

import { GLOBAL_ERROR_MESSAGE, FORM_IS_CLOSED_ERROR_MEESSAGE } from 'utils/constants';

import './Welcome.scss';

const Welcome = ({ match, isPreview, acceptConditions }) => {
  const [tcAccepted, setTcAccepted] = useState(false);
  const [registrationData, setRegistrationData] = useState(null);
  const [showTcNotAccepted, setShowTcNotAccepted] = useState(false);
  const [isFormIsNoLongerAvailable, setIsFormIsNoLongerAvailable] = useState(false);

  const loadingBarRef = useRef();

  const companyContext = useContext(CompanyNameContext);

  useEffect(() => {
    startLoadingBar(loadingBarRef.current);

    if (isPreview) {
      getRegistrationDataPreview(match.params.sharedKey, match.params.profileId)
        .then((response) => handleRegistrationDataResponse(response))
        .catch(() => toast.error(GLOBAL_ERROR_MESSAGE))
        .finally(() => endLoadingBar(loadingBarRef.current));

      return;
    }

    getRegistrationData(match.params.sharedKey, match.params.profileId)
      .then((response) => handleRegistrationDataResponse(response))
      .catch(() => toast.error(GLOBAL_ERROR_MESSAGE))
      .finally(() => endLoadingBar(loadingBarRef.current));
  }, []);

  const handleRegistrationDataResponse = (response) => {
    if (response?.status === 200) {
      if (response.payload.Errors?.length) {
        var error = response.payload.Errors[0];
        if (error === FORM_IS_CLOSED_ERROR_MEESSAGE) {
          setIsFormIsNoLongerAvailable(true);
          return;
        }

        toast.error(error);
        return;
      }

      if (response.payload.Successes?.length) {
        var success = response.payload.Successes[0];
        toast.success(success);
      }

      const registrationData = response.payload.Data;
      setRegistrationData(registrationData);
      companyContext.setCompanyNameAndLogo(registrationData.CompanyName, registrationData.CompanyLogoUrl);
      if (!registrationData.IsIntroSlideVisible && !registrationData.IsOnHold) {
        acceptConditions(true);
      }
    } else {
      toast.error(GLOBAL_ERROR_MESSAGE);
    }
  };

  const tcAcceptChanged = (event) => {
    setTcAccepted(event.target.checked);
    setShowTcNotAccepted(false);
  };

  const navigateToRegisterPage = () => {
    if (registrationData.UserAgreementId && !tcAccepted) {
      setShowTcNotAccepted(true);

      return;
    }

    acceptConditions(!registrationData.UserAgreementId || tcAccepted);
  };
  const ErrorPanel = ({ html }) => (
    <div className="welcome">
      <Panel noBorder={match.params.embed === 'embed'}>
        <div className="welcome-onhold">
          <div
            className="server-html"
            dangerouslySetInnerHTML={{
              __html: html
            }}
          />
        </div>
      </Panel>
      <AppLoadingBar ref={loadingBarRef} />
    </div>
  );

  if (isFormIsNoLongerAvailable) {
    return <ErrorPanel html={'This form is no longer available'} />;
  }

  if (!registrationData) {
    return <AppLoadingBar ref={loadingBarRef} />;
  }

  if (registrationData.IsOnHold) {
    return <ErrorPanel html={registrationData.OnHoldText} />;
  }

  const images = registrationData.Images?.map((value) => ({ original: value }));
  const videos = registrationData.Videos?.map((video) => ({ videoUrl: video }));

  const showGallery = (images && !!images.length) || (videos && !!videos.length);

  return (
    <div className="welcome">
      <Panel noBorder={match.params.embed === 'embed'}>
        {showGallery && <PhotoSlider images={images} videos={videos} />}

        <div className="welcome-content">
          <div className="welcome-introduction">
            {registrationData.Introduction && (
              <div
                className="server-html"
                dangerouslySetInnerHTML={{
                  __html: textWithClickableLink(registrationData.Introduction)
                }}
              />
            )}
          </div>
          {registrationData.UserAgreementId && (
            <div className="welcome-tc-container">
              <Checkbox onChange={tcAcceptChanged} />
              <div className={classnames({ 'tc-error': showTcNotAccepted })}>
                <Link to={`/${match.params.sharedKey}/${match.params.profileId}/terms-and-conditions`} target="_blank">
                  {registrationData.TermsAndConditionsStart?.length > 0
                    ? `${registrationData.TermsAndConditionsStart} `
                    : ''}
                  {registrationData.TermsAndConditionsMiddle}
                  {registrationData.TermsAndConditionsEnd?.length > 0
                    ? ` ${registrationData.TermsAndConditionsEnd}`
                    : ''}
                </Link>{' '}
              </div>
            </div>
          )}
          <div className="welcome-button-container">
            <Button onClick={navigateToRegisterPage} error={showTcNotAccepted}>
              {registrationData.RegisterButton}
            </Button>
          </div>
        </div>
      </Panel>
      <AppLoadingBar ref={loadingBarRef} />
    </div>
  );
};

Welcome.propTypes = {
  match: PropTypes.object,
  acceptConditions: PropTypes.func,
  isPreview: PropTypes.bool
};

export default Welcome;
