import React, { useState, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useHistory, generatePath, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Panel from 'components/Panel';
import AppLoadingBar from 'components/AppLoadingBar';
import Button from 'components/Button';
import ReCaptcha from 'components/ReCaptcha';
import Input from 'components/Input';
import SessionHadEndedMessage from 'components/SessionHadEndedMessage';

import { useCompanyNameAndLogo } from 'hooks/useCompanyNameAndLogo';

import * as routes from '../../routes/routes';

import { startLoadingBar, endLoadingBar } from 'utils/loadingBar';
import { SESSION_HAS_ENDED_ERROR_MESSAGE } from 'utils/constants';

import { getActor } from 'actions/profileUpdate';

const VerifyCode = () => {
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const reCaptchaRef = useRef();
  const loadingBarRef = useRef();

  const { profileUpdateQuery } = useParams();

  const { isExpired, isLoading } = useCompanyNameAndLogo(loadingBarRef, decodeURIComponent(profileUpdateQuery));

  const validationSchema = Yup.object().shape({
    Code: Yup.string().required('Code is required')
  });

  const submit = async (values) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    startLoadingBar(loadingBarRef.current);

    if (!values.CaptchaToken) {
      const token = await reCaptchaRef.current.executeAsync();
      values.CaptchaToken = token;
    }

    let response;
    try {
      response = await getActor(values.Code);
    } catch (error) {
      if (error?.message === SESSION_HAS_ENDED_ERROR_MESSAGE)
        toast.error("Oops! The code you entered doesn't match. Double-check your email and input the correct code.", {
          position: toast.POSITION.BOTTOM_CENTER
        });
      else
        toast.error(typeof error === 'string' ? error : error.message, {
          position: toast.POSITION.BOTTOM_CENTER
        });

      endLoadingBar(loadingBarRef.current);
      setIsSubmitting(false);
      return;
    }

    response.actor.attachments = response.actor.attachments.map((attachment) => ({
      ...attachment,
      imageUrl: attachment.originalImageUri,
      thumbnailUrl: attachment.thumbnailImageUri,
      videoUrl: attachment.fileName
    }));

    history.push(generatePath(routes.UPDATE, { apiKey: values.Code }), response);
    endLoadingBar(loadingBarRef.current);
  };

  return (
    <div className="welcome">
      <Panel>
        <div className="welcome-content">
          {isExpired ? (
            <SessionHadEndedMessage />
          ) : (
            <>
              <div className="welcome-introduction">
                <h1>Verify Your Identity</h1>
                <p>To proceed, click on the verification link sent to your email or manually input the code below.</p>

                {!isLoading && (
                  <Formik
                    initialValues={{ Code: '' }}
                    onSubmit={submit}
                    validateOnChange={false}
                    validateOnMount={false}
                    validationSchema={validationSchema}
                  >
                    {({ setFieldValue, errors }) => (
                      <Form>
                        <div className="register-recaptcha-container">
                          <ReCaptcha fRef={reCaptchaRef} />
                        </div>
                        <div className="register-form-group">
                          <Field
                            component={Input}
                            name="Code"
                            label="Code"
                            placeholder="Code"
                            setFieldValue={setFieldValue}
                            error={errors.Code}
                          />
                        </div>

                        <div className="welcome-button-container">
                          <Button type="submit">SIGN IN</Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </>
          )}
        </div>
      </Panel>
      <AppLoadingBar ref={loadingBarRef} />
    </div>
  );
};

export default VerifyCode;
