import React from 'react';
import LoadingBar from 'react-top-loading-bar';

import './AppLoadingBar.scss';

const AppLoadingBar = React.forwardRef((props, ref) => {
  const { onLoaderFinish, startProgress, progress } = props;

  if (startProgress) {
    return (
      <LoadingBar progress={progress} onLoaderFinished={onLoaderFinish} color="#2d97de" height={5} shadow={false} />
    );
  } else {
    return <LoadingBar {...props} ref={ref} color="#2d97de" height={5} shadow={false} />;
  }
});

export default AppLoadingBar;
