import React, { useRef, useState, useContext, useEffect, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { toast } from 'react-toastify';

import Text from 'components/Text';
import Button from 'components/Button';
import Panel from 'components/Panel';
import AppLoadingBar from 'components/AppLoadingBar';
import { CompanyNameContext } from 'components/CompanyNameContext';

import { GLOBAL_ERROR_MESSAGE } from 'utils/constants';

import { getProfileDelete, deleteProfile } from 'actions/messages';

import styles from './DeleteProfile.module.scss';

const TABS = {
  START: 'START',
  RECONSIDERED: 'RECONSIDERED',
  DELETED: 'DELETED'
};

const DeleteProfile = ({ onReconsider, profileDeleteData, deleteAction }) => {
  const [data, setData] = useState(profileDeleteData);
  const [activeTab, setActiveTab] = useState(TABS.START);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const loadingBarRef = useRef();

  const companyContext = useContext(CompanyNameContext);
  const { params } = useRouteMatch();

  useEffect(() => {
    if (data) {
      return;
    }
    
    startLoadingBar();
    getProfileDelete(params.profileId, params.messageId, params.faceId)
      .then((response) => {
        endLoadingBar();
        if (response.payload.Errors && response.payload.Errors.length) {
          const error = response.payload.Errors[0];
          toast.error(error);
          return;
        }

        setData(response.payload.Data);
        companyContext.setCompanyNameAndLogo(response.payload.Data.CompanyName, response.payload.Data.CompanyLogoUrl);
      })
      .catch(() => {
        endLoadingBar();
        toast.error(GLOBAL_ERROR_MESSAGE);
      });
  }, []);

  const startLoadingBar = () => {
    setTimeout(() => {
      loadingBarRef.current.continuousStart();
    }, 100);
  };

  const endLoadingBar = () => {
    setTimeout(() => {
      loadingBarRef.current.complete();
    }, 100);
  };

  const reconsider = () => {
    if (onReconsider) {
      onReconsider();
      return;
    }
    
    setActiveTab(TABS.RECONSIDERED);
  };

  const removeProfile = () => {
    if (isSubmiting) {
      return;
    }

    sendDeleteRequest(data.DeleteButtonId, () => {
      setActiveTab(TABS.DELETED);
    });
  };

  const sendDeleteRequest = (buttonId, onSuccess) => {
    setIsSubmiting(true);
    startLoadingBar();

    const deleteActionCall = deleteAction || deleteProfile;

    deleteActionCall(params.profileId, params.messageId, params.faceId, {
      OptionIds: [buttonId]
    })
      .then((response) => {
        setIsSubmiting(false);
        endLoadingBar();

        if (!response || response.status !== 200) {
          toast.error(GLOBAL_ERROR_MESSAGE);
          return;
        }

        if (response.payload.Errors && response.payload.Errors.length) {
          const error = response.payload.Errors[0];
          toast.error(error);
          return;
        }

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        endLoadingBar();
        setIsSubmiting(false);
        toast.error(GLOBAL_ERROR_MESSAGE);
      });
  };

  const renderStart = () => {
    return (
      <>
        <Text>{data.DeleteWarning}</Text>
        <div className={styles['buttons-container']}>
          <Button onClick={reconsider}>{data.ReconsiderButton}</Button>
          <Button danger onClick={removeProfile}>
            {data.ConfirmDeleteButton}
          </Button>
        </div>
      </>
    );
  };

  const renderReconsidered = () => {
    return (
      <>
        <Text>{data.ReconsideredMessage}</Text>
      </>
    );
  };

  const renderDeleted = () => {
    return (
      <>
        <Text>{data.DeletedMessage}</Text>
      </>
    );
  };

  return (
    <>
      {data && (
        <div className={styles['delete-profile']}>
          <Panel forMessage={activeTab !== TABS.START}>
            {activeTab === TABS.START && renderStart()}
            {activeTab === TABS.RECONSIDERED && renderReconsidered()}
            {activeTab === TABS.DELETED && renderDeleted()}
          </Panel>
        </div>
      )}
      <AppLoadingBar ref={loadingBarRef} />
    </>
  );
};

export default DeleteProfile;
