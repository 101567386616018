import { _fetch } from 'utils/fetch';
import { GLOBAL_ERROR_MESSAGE, SESSION_HAS_ENDED_ERROR_MESSAGE } from 'utils/constants';
import * as accessCodeStatus from 'utils/constants/accessCodeStatus';
import * as httpStatus from 'utils/constants/httpStatus';
import * as errorCode from 'utils/constants/errorCode';

const API_URL = () => global.config[process.env.NODE_ENV].profileUpdateUrl;

export const getCode = async (sharedLinkCode) => {
  let response;
  try {
    response = await _fetch(`${API_URL()}/getcode`, {
      method: 'POST',
      external: true,
      body: { sharedLinkCode }
    });
  } catch (error) {
    throw new Error(GLOBAL_ERROR_MESSAGE);
  }

  const result = handleResponse(response);
  return result;
};

export const getActor = async (apiKey) => {
  let response;
  try {
    response = await _fetch(`${API_URL()}/actor`, {
      method: 'GET',
      external: true,
      headers: { 'api-key': apiKey }
    });
  } catch (error) {
    throw new Error(GLOBAL_ERROR_MESSAGE);
  }

  const result = handleResponse(response);
  return result;
};

export const updateActor = async (apiKey, body) => {
  let response;
  try {
    response = await _fetch(`${API_URL()}/actor`, {
      method: 'PUT',
      external: true,
      headers: { 'api-key': apiKey },
      body
    });
  } catch (error) {
    throw new Error(GLOBAL_ERROR_MESSAGE);
  }

  const result = handleResponse(response);
  return result;
};

export const getCompanyDetails = async (sharedLinkCode) => {
  let response;
  try {
    response = await _fetch(`${API_URL()}/details`, {
      method: 'GET',
      external: true,
      headers: { 'api-key': sharedLinkCode }
    });
  } catch (error) {
    throw new Error(GLOBAL_ERROR_MESSAGE);
  }

  const result = handleResponse(response);
  return result;
};

const handleResponse = (response) => {
  if (response?.status === httpStatus.UNAUTHORIZED) throw new Error(SESSION_HAS_ENDED_ERROR_MESSAGE);

  if (
    [errorCode.ALREADY_UPDATED, errorCode.EXPIRED, errorCode.FAILED_TO_LOAD, errorCode.EXPIRED_REGISTRATION].includes(
      response?.payload?.errorCode
    )
  )
    throw new Error(SESSION_HAS_ENDED_ERROR_MESSAGE);

  const error = response?.payload?.errorMessage;
  if (error) throw new Error(error);

  if (response.status === accessCodeStatus.EXPIRED) throw new Error('This link has expired.');

  if (response?.status !== httpStatus.OK) throw new Error(GLOBAL_ERROR_MESSAGE);

  const success = response.payload?.Successes?.[0];
  return { ...response.payload, successMessage: success };
};
