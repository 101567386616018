import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { textWithClickableLink } from 'utils/htmlToLink';

import Panel from 'components/Panel';

import './RegistrationSuccess.scss';

const RegistrationSuccess = ({ message }) => {
  const { embed } = useParams();

  return (
    <div className="registration-success">
      <Panel noBorder={embed === 'embed'}>
        <div
          className="server-html"
          dangerouslySetInnerHTML={{
            __html: textWithClickableLink(message)
          }}
        />
      </Panel>
    </div>
  );
};

RegistrationSuccess.propTypes = {
  message: PropTypes.any,
  buttonText: PropTypes.string,
  buttonCallback: PropTypes.func
};

export default RegistrationSuccess;
