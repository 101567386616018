import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Routes from './routes';

import { loadConfig } from 'utils/fetch';
import { startLoadingBar, endLoadingBar } from 'utils/loadingBar';

import ErrorBoundary from 'components/ErrorBoundary';
import { CompanyNameContext } from 'components/CompanyNameContext';
import AppLoadingBar from 'components/AppLoadingBar';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const App = () => {
  const loadingBarRef = useRef();

  const currentYear = new Date().getFullYear();

  const setCompanyNameAndLogo = (name, logo) => {
    const newState = { ...state };
    if (name) newState.companyName = name;
    if (logo) newState.companyLogo = logo;

    setState(newState);
  };

  const isEmpty = () => !state.companyName && !state.companyLogo;

  const updateEmbedState = (embedState) => {
    if (isEmbed === embedState) return;

    setIsEmbed(embedState);
  };

  const [state, setState] = useState({
    companyName: null,
    companyLogo: null,
    setCompanyNameAndLogo,
    isEmpty,
    updateEmbedState
  });

  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [isEmbed, setIsEmbed] = useState(false);

  useEffect(() => {
    startLoadingBar(loadingBarRef.current);
    loadConfig().then(() => {
      setIsConfigLoaded(true);
      endLoadingBar(loadingBarRef.current);
    });
  }, []);

  return (
    <ErrorBoundary>
      <CompanyNameContext.Provider value={{ ...state, isEmbed }}>
        <div className="app">
          <ToastContainer
            position={toast.POSITION.TOP_CENTER}
            hideProgressBar={true}
            className="toast-container"
            toastClassName="app-toast"
          />
          {!isEmbed && (
            <header className="app-header">
              {!state.companyLogo && <div>{state.companyName}</div>}
              {state.companyLogo && <img className="logo" src={state.companyLogo} alt="log" />}
            </header>
          )}
          <div className={cx('app-content', { embed: isEmbed })}>{isConfigLoaded && <Routes />}</div>
          {!isEmbed && (
            <footer className="app-footer">
              <div>
                This form was built with <a href="https://production.tools/">Production Tools</a>
              </div>
              <div>
                <div>©{currentYear}, All Rights Reserved.</div>
                <div className="app-footer-recaptcha">This site is protected by reCAPTCHA and the Google</div>
                <div className="app-footer-recaptcha">
                  <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                  </a>{' '}
                  apply.
                </div>
              </div>
            </footer>
          )}
        </div>
        <AppLoadingBar ref={loadingBarRef} />
      </CompanyNameContext.Provider>
    </ErrorBoundary>
  );
};

App.contextTypes = {
  router: PropTypes.object,
  location: PropTypes.object
};

export default App;
