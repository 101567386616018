import React from 'react';
import EditOn from '../../img/Edit-On.png';
import EditOff from '../../img/Edit-Circle-Off.png';
import styles from './styles.module.scss';

const MediaEditButton = ({ active }) => {
  return (
    <div className={`${styles['video-edit-button']} ${active ? styles['edit-active'] : styles['edit-inactive']}`}>
      {' '}
      <img alt="edit button" src={active ? EditOn : EditOff} />
    </div>
  );
};

export default MediaEditButton;
