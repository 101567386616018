import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/Dropdown';
import Label from 'components/Label';

import styles from './DateInputDropdown.module.scss';

export const DateInputDropdown = ({ field, label, onChange = () => {}, setFieldValue = () => {}, error }) => {
  const onDateChange = (value) => {
    if (setFieldValue && value && typeof value === 'object') {
      const formatedDate = formatDate(value);
      setFieldValue(field.name, formatedDate, true);
    } else if (!value) {
      setFieldValue(field.name, '', true);
    } else {
      setFieldValue(field.name, value, true);
    }

    if (onChange) {
      onChange(value);
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
  };

  const updateDaysOptions = () => {
    if (!selectedMonth || !selectedYear) {
      setDaysOptions(defaultDaysOptions);
      return;
    }

    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    setDaysOptions([...Array(daysInMonth).keys()]);

    if (!selectedDay || daysInMonth >= selectedDay) {
      return;
    }

    setSelectedDay(null);
  };

  const now = new Date().getUTCFullYear();
  const yearsOptions = Array(now - (now - 120))
    .fill('')
    .map((v, idx) => now - idx);
  const monthsOptions = [...Array(12).keys()];
  const defaultDaysOptions = [...Array(31).keys()];

  // field.value && new Date(field.value), not works because on empty string case it returns empty string instead of null
  const currentDate = field.value ? new Date(field.value) : null;
  const [selectedYear, setSelectedYear] = useState(currentDate?.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(currentDate?.getMonth() + 1);
  const [selectedDay, setSelectedDay] = useState(currentDate?.getDate());
  const [daysOptions, setDaysOptions] = useState(defaultDaysOptions);
  const [hasDateError, setHasDateError] = useState(false);

  useEffect(() => {
    updateDaysOptions();
    if (!selectedYear && !selectedMonth && !selectedDay) {
      setHasDateError(false);
      onDateChange('');
      return;
    }

    if (!selectedYear || !selectedMonth || !selectedDay) {
      setHasDateError(true);
      onDateChange('error');
      return;
    }

    setHasDateError(false);
    const date = new Date(selectedYear, selectedMonth - 1, selectedDay);
    onDateChange(date);
  }, [selectedYear, selectedMonth, selectedDay]);

  return (
    <div className={styles['date-container']}>
      <div>{label && <Label error={error || hasDateError}>{label}</Label>}</div>
      <div className={styles['date-controls']}>
        <div>
          <Dropdown
            className={styles['date-month']}
            field={{ value: selectedMonth }}
            error={error || hasDateError}
            onChange={(e) => {
              setSelectedMonth(e.target.value);
            }}
          >
            {monthsOptions.map((month) => (
              <option key={month + 1} value={month + 1}>
                {month + 1}
              </option>
            ))}
          </Dropdown>
        </div>
        <div>
          <Dropdown
            className={styles['date-day']}
            field={{ value: selectedDay }}
            error={error || hasDateError}
            onChange={(e) => {
              setSelectedDay(e.target.value);
            }}
          >
            {daysOptions.map((day) => (
              <option key={day + 1} value={day + 1}>
                {day + 1}
              </option>
            ))}
          </Dropdown>
        </div>
        <div>
          <Dropdown
            className={styles['date-year']}
            field={{ value: selectedYear }}
            error={error || hasDateError}
            onChange={(e) => {
              setSelectedYear(e.target.value);
            }}
          >
            {yearsOptions.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

DateInputDropdown.propTypes = {
  field: PropTypes.object,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  error: PropTypes.string
};

export default DateInputDropdown;
