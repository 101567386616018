export const FOOT_PLACEHOLDER = 'Feet';
export const INCHES_PLACEHOLDER = 'Inches';
export const CENTIMETERS_PLACEHOLDER = 'Cm';
export const POUNDS_PLACEHOLDER = 'Pounds';
export const KILOGRAMS_PLACEHOLDER = 'Kg';
export const SQUARE_FEET_PLACEHOLDER = 'Square Feet';
export const SQUARE_METERS_PLACEHOLDER = 'Square meters';

export const GLOBAL_ERROR_MESSAGE = 'Oops! Something went wrong. Please try again or contact administrator';
export const SESSION_HAS_ENDED_ERROR_MESSAGE =
  'Your upload session has ended. Please try again and fill in your details in less than two hours.';
export const FORM_IS_CLOSED_ERROR_MEESSAGE = 'Sorry, but this form is closed or temporarily paused.';
