export const NONE = 0;
export const TEXT_BOX = 10;
export const DROP_DOWN = 20;
export const DROP_DOWN_MULTIPLE = 21;
export const CHECK_BOX = 24;
export const CHECK_BOX_MULTIPLE = 25;
export const DATE_OF_BIRTH = 30;
export const PHONE_NUMBER = 32;
export const FULL_PHONE = 33;
export const EMAIL = 40;
export const COMMENTS = 50;
export const MEASUREMENT_HEIGHT = 60;
export const MEASUREMENT_WEIGHT = 61;
export const MEASUREMENT_SIMPLE = 62;
export const MEASUREMENT_AREA = 63;
export const LOCATION = 70;
export const SEARCH_KEYWORD = 80;
export const UPLOADED_DATE_RANGE = 81;
export const EDITED_DATE_RANGE = 82;
export const INVISIBLE = 99999;
