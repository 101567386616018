import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Cropper from 'react-cropper';
import * as loadImage from 'blueimp-load-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faRedo, faSearchPlus, faSearchMinus } from '@fortawesome/free-solid-svg-icons';

import Icon from 'components/Icon';
import MenuItem from 'components/MenuItem';
import * as menuItemTypes from 'components/MenuItem/types';
import MediaEditButton from 'components/MediaEditButton';
import Popup from 'components/Popup';

import * as fileGroup from 'utils/constants/fileGroup';

import AttachmentImage from './Attachment.png';
import SoundImage from './Sound.png';
import VideoImage from './Video.png';

import 'cropperjs/dist/cropper.css';
import './FileUpload.scss';
import styles from './FileUpload.module.scss';

const initialState = {
  isContextPopupOpen: false,
  scale: 1,
  rotate: 0,
  aspectRatio: null,
  dragOver: false,
  uploadingPhotos: false
};
class FileUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState, files: props.files || [] };

    this.cropper = React.createRef();

    this.props.setFieldValue(this.props.name, props.files, true, true);

    if (this.props.validationName) {
      this.props.setFieldValue(this.props.validationName, !!props.files?.length || null, true);
    }
  }

  isFileImage = (file) => {
    return file && file['type'].split('/')[0] === 'image';
  };

  getUploadFilesCounts = () => {
    const { files } = this.state;
    const imagesCount = files.filter((file) => file.fileGroup === fileGroup.IMAGE).length;
    const videosCount = files.filter((file) => file.fileGroup === fileGroup.VIDEO_ATTACHMENT).length;
    const audiosCount = files.filter((file) => file.fileGroup === fileGroup.AUDIO).length;
    const docsCount = files.filter((file) => file.fileGroup === fileGroup.DOCUMENT).length;

    return { imagesCount, videosCount, audiosCount, docsCount };
  };

  onDrop = async (acceptedFiles) => {
    this.setState({ dragOver: false });

    if (this.props.filesValidation) {
      const uploadFilesCounts = this.getUploadFilesCounts();
      acceptedFiles = this.props.filesValidation(acceptedFiles, uploadFilesCounts);
    }

    if (this.props.isPreview) {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onload = () => {
          const binaryStr = reader.result;

          if (this.props.validationName) {
            this.props.setFieldValue(this.props.validationName, true, true);
          }

          this.setState({
            files: [
              ...this.state.files,
              {
                isPhoto: this.isFileImage(file),
                imageSrc: binaryStr,
                attachmentId: this.state.files.length
              }
            ]
          });
        };
        reader.readAsDataURL(file);
      });

      return;
    }

    this.setState({ uploadingPhotos: true });
    const uploadedFiles = await this.props.onSelect(acceptedFiles, this.isFileImage);

    this.setState({ uploadingPhotos: false });
    const files = [...this.state.files, ...uploadedFiles];

    this.props.setFieldValue(this.props.name, files, true, true);

    if (this.props.validationName) {
      this.props.setFieldValue(this.props.validationName, !!files.length || null, true);
    }

    this.setState({
      files: files
    });
  };

  openPopup = (event) => {
    event.preventDefault();
    this.setState({
      isContextPopupOpen: true
    });
  };

  closePopup = () => {
    this.setState({
      currentCropImage: null,
      isContextPopupOpen: false,
      cropImageBase64: null
    });
  };

  openImageCrop = (image) => () => {
    loadImage(
      image.imageUrl ? image.imageUrl : image.imageSrc,
      (canvas) => {
        this.setState({
          currentCropImage: image,
          isContextPopupOpen: true,
          rotate: 0,
          scale: 1,
          cropImageBase64: canvas.toDataURL()
        });
      },
      { orientation: true }
    );
  };

  cropImage = async () => {
    const crop = this.cropper.current.getData();
    if (this.props.isPreview) {
      this.setState({
        currentCropImage: null,
        isContextPopupOpen: false
      });
      return;
    }

    const response = await this.props.onImageCrop({
      xValue: Math.floor(Math.round(crop.x) / crop.scaleX),
      yValue: Math.floor(Math.round(crop.y) / crop.scaleY),
      width: Math.floor(crop.width / crop.scaleX),
      height: Math.floor(crop.height / crop.scaleY),
      rotate: this.state.rotate,
      attachmentId: this.state.currentCropImage.attachmentId
    });

    const files = this.state.files.reduce((result, file) => {
      if (file.attachmentId === response?.attachmentId) {
        result.push(response);
      } else {
        result.push(file);
      }

      return result;
    }, []);

    this.setState({
      files: files,
      currentCropImage: null,
      isContextPopupOpen: false,
      cropImageBase64: null
    });
  };

  adjustRotate = (value) => () => {
    const rotate = this.state.rotate + value;
    this.cropper.current.rotateTo(rotate);

    this.setState({
      rotate
    });
  };

  zoom = (value) => () => {
    const scale = this.state.scale * (1 + value);
    this.cropper.current.scale(scale);

    this.setState({
      scale
    });
  };

  setAspectRatio = (aspectRatio) => () => {
    this.setState({
      aspectRatio
    });
  };

  deleteImage = (imageAttachmentId) => () => {
    const files = this.state.files.reduce((result, file) => {
      if (file.attachmentId !== imageAttachmentId) {
        result.push(file);
      }

      return result;
    }, []);

    if (!this.props.isPreview) {
      this.props.onImageDelete(imageAttachmentId);
    }

    this.props.setFieldValue(this.props.name, files, true);

    if (this.props.validationName) {
      this.props.setFieldValue(this.props.validationName, !!files.length || null, true);
    }
    this.setState({
      files: files
    });
  };

  useAsThumbnail = (image) => () => {
    const files = this.state.files
      .filter((file) => file.attachmentId !== image.attachmentId)
      .map((file, index) => {
        return { ...file, sortOrder: index + 1 };
      });
    const newPhotosOrdered = [
      {
        ...image,
        sortOrder: 0
      },
      ...files
    ];

    if (!this.props.isPreview) {
      this.props.onUpdateSortOrder(
        [
          ...newPhotosOrdered.map((file) => {
            return {
              attachmentId: file.attachmentId,
              sortOrder: file.sortOrder
            };
          })
        ],
        true
      );
    }

    this.props.setFieldValue(this.props.name, newPhotosOrdered, true, true);

    this.setState({
      files: newPhotosOrdered
    });
  };

  moveImageOrder = (image, index) => () => {
    const firstPhotoAttachmentId = this.state.files[0].attachmentId;
    const files = this.state.files.filter((file) => file.attachmentId !== image.attachmentId);

    files.splice(index, 0, image);

    const newPhotosOrdered = files.map((file, index) => {
      return { ...file, sortOrder: index };
    });

    if (!this.props.isPreview) {
      this.props.onUpdateSortOrder(
        [
          ...newPhotosOrdered.map((file) => {
            return {
              attachmentId: file.attachmentId,
              sortOrder: file.sortOrder
            };
          })
        ],
        firstPhotoAttachmentId !== newPhotosOrdered[0].attachmentId
      );
    }

    this.props.setFieldValue(this.props.name, newPhotosOrdered, true, true);

    this.setState({
      files: newPhotosOrdered
    });
  };

  getImageSrc(file) {
    if (file.fileGroup === fileGroup.IMAGE) {
      return file.thumbnailUrl ? file.thumbnailUrl : file.imageSrc;
    }
    if (file.fileGroup === fileGroup.AUDIO) {
      return SoundImage;
    }

    if (file.fileGroup === fileGroup.VIDEO_ATTACHMENT) {
      return VideoImage;
    }

    return AttachmentImage;
  }

  render() {
    return (
      <div className="photos-container">
        {this.state.uploadingPhotos && <div className="photos-container-uploading-overlay"></div>}
        {this.state.isContextPopupOpen && <div className="photo-editing-overlay"></div>}
        <div className="photos-container-uploaded-photos">
          {this.state.files.map((file, index) => {
            return (
              <div key={`${index}_${file.attachmentId}`} className="photo">
                <div className="photo-container">
                  <img
                    className={
                      file.fileGroup === fileGroup.AUDIO || file.fileGroup === fileGroup.VIDEO_ATTACHMENT
                        ? 'lighten'
                        : ''
                    }
                    src={this.getImageSrc(file)}
                    alt=""
                  />
                  {file.fileGroup !== fileGroup.IMAGE && (
                    <>
                      <span className="attachment-title-text">{file.decodedFileName}</span>
                    </>
                  )}
                  <div></div>
                </div>
                <div className="photo-overlay">
                  <div className="photo-overlay-top-row">
                    <Popup
                      trigger={(open) => MediaEditButton({ active: open })}
                      key={this.state.isContextPopupOpen}
                      position="left top"
                      arrow={false}
                      className="menu"
                      mouseLeaveDelay={100}
                      contentStyle={{ marginLeft: '15px', marginTop: '15px', width: '160px', borderRadius: '4px' }}
                    >
                      <div className={`${styles['menu-items']}`}>
                        {file.fileGroup === fileGroup.IMAGE && (
                          <>
                            <MenuItem onClick={this.openImageCrop(file)} type={menuItemTypes.CROP}>
                              Crop / Rotate
                            </MenuItem>
                            <MenuItem
                              onClick={this.useAsThumbnail(file)}
                              type={menuItemTypes.THUMBNAIL}
                              active={index === 0}
                            >
                              Use as thumbnail
                            </MenuItem>
                          </>
                        )}
                        <MenuItem onClick={this.deleteImage(file.attachmentId)} type={menuItemTypes.DELETE}>
                          Delete
                        </MenuItem>
                      </div>
                    </Popup>
                  </div>
                  <div className="photo-overlay-bottom-row">
                    {index !== 0 ? (
                      <div className="photo-move-left" onClick={this.moveImageOrder(file, index - 1)}></div>
                    ) : (
                      <div />
                    )}
                    {index !== this.state.files.length - 1 && (
                      <div className="photo-move-right" onClick={this.moveImageOrder(file, index + 1)}></div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <Dropzone
            accept={this.props.accept || ''}
            onDrop={this.onDrop}
            onDragEnter={() => {
              this.setState(() => {
                return { dragOver: true };
              });
            }}
            onDragLeave={() => {
              this.setState(() => {
                return { dragOver: false };
              });
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="photo empty">
                {!!this.state.dragOver && <div className="drag-over-overlay"></div>}
                <input {...getInputProps()} />
                <Icon icon="plusCircleOutline" />
              </div>
            )}
          </Dropzone>
        </div>
        <Popup
          open={this.state.isContextPopupOpen}
          position="right center"
          className="crop"
          closeOnDocumentClick={false}
          closeOnEscape={false}
          overlayStyle={{
            backgroundColor: 'transparent'
          }}
          lockScroll={true}
        >
          <div className="crop-content-wrapper">
            <div className="popup-header">
              <div className="popup-close-button" onClick={this.closePopup}></div>
            </div>
            <div className="crop-canvas">
              {this.state.currentCropImage && (
                <Cropper
                  ref={this.cropper}
                  src={this.state.cropImageBase64}
                  style={{ height: '100%', width: '100%' }}
                  crop={this.onCropChange}
                  zoomOnWheel={false}
                  aspectRatio={this.state.aspectRatio}
                  autoCropArea={1}
                  toggleDragModeOnDblclick={false}
                  checkOrientation={true}
                  rotatable={true}
                  scalable={true}
                />
              )}
            </div>
            <div className="crop-controls">
              <div className="crop-buttons">
                <div className="crop-buttons-row">
                  {aspectRatios.map((aspectRatio) => (
                    <div
                      key={aspectRatio.name}
                      className={`crop-button ${this.state.aspectRatio === aspectRatio.value && 'active'}`}
                      onClick={this.setAspectRatio(aspectRatio.value)}
                    >
                      {aspectRatio.name}
                    </div>
                  ))}
                </div>
                <div className="crop-buttons-row">
                  <div className="crop-button" title="Rotate -10 degrees" onClick={this.adjustRotate(-10)}>
                    <FontAwesomeIcon icon={faUndo} />
                  </div>
                  <div className="crop-button" title="Rotate 10 degrees" onClick={this.adjustRotate(10)}>
                    <FontAwesomeIcon icon={faRedo} />
                  </div>
                  <div className="crop-button" onClick={this.zoom(0.1)}>
                    <FontAwesomeIcon icon={faSearchPlus} title="Zoom In" />
                  </div>
                  <div className="crop-button" onClick={this.zoom(-0.1)}>
                    <FontAwesomeIcon icon={faSearchMinus} title="Zoom Out" />
                  </div>
                </div>
                <div className="crop-buttons-row">
                  <div className="crop-button" onClick={this.adjustRotate(-90)}>
                    <FontAwesomeIcon icon={faUndo} title="Rotate -90 degrees" />
                  </div>

                  <div className="crop-button" onClick={this.adjustRotate(90)}>
                    <FontAwesomeIcon icon={faRedo} title="Rotate 90 degrees" />
                  </div>
                  <div className="crop-button" onClick={this.zoom(0.4)}>
                    <FontAwesomeIcon icon={faSearchPlus} title="Zoom In 4x" />
                  </div>
                  <div className="crop-button" onClick={this.zoom(-0.4)}>
                    <FontAwesomeIcon icon={faSearchMinus} title="Zoom Out 4x" />
                  </div>
                </div>
                <div className="crop-button crop-button-done" onClick={this.cropImage}>
                  Done
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

const aspectRatios = [
  {
    name: '1:1',
    value: 1
  },
  {
    name: '15:10',
    value: 15 / 10
  },
  {
    name: '10:15',
    value: 10 / 15
  },
  {
    name: 'Free',
    value: null
  }
];

FileUpload.propTypes = {
  name: PropTypes.string,
  onSelect: PropTypes.func,
  setFieldValue: PropTypes.func,
  isPreview: PropTypes.bool,
  validationName: PropTypes.string,
  onImageCrop: PropTypes.func,
  onImageDelete: PropTypes.func,
  onUpdateSortOrder: PropTypes.func,
  files: PropTypes.array
};

export default FileUpload;
