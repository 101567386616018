import React, { useEffect, useContext, useState } from 'react';
import { CompanyNameContext } from 'components/CompanyNameContext';
import { getCompanyDetails } from 'actions/profileUpdate';
import { startLoadingBar, endLoadingBar } from 'utils/loadingBar';
import { SESSION_HAS_ENDED_ERROR_MESSAGE } from 'utils/constants';
import { toast } from 'react-toastify';

export const useCompanyNameAndLogo = (loadingBarRef = {}, profileUpdateQuery = '', onSuccess = null) => {
  const { setCompanyNameAndLogo, isEmpty } = useContext(CompanyNameContext);

  const { isExpired, handleError } = useErrorHandler();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isEmpty()) {
      startLoadingBar(loadingBarRef.current);
      getCompanyDetails(profileUpdateQuery)
        .then((response) => {
          setCompanyNameAndLogo(response.companyName, response.companyLogoUrl);
          setIsLoading(false);
        })
        .catch(handleError)
        .finally(() => {
          endLoadingBar(loadingBarRef.current);
        });
    }
  }, []);

  return { isExpired, isLoading };
};

export const useErrorHandler = () => {
  const [isExpired, setIsExpired] = useState(false);

  const handleError = (error) => {
    const errorMessage = typeof error === 'string' ? error : error.message;
    if (errorMessage === SESSION_HAS_ENDED_ERROR_MESSAGE) {
      setIsExpired(true);
    } else toast.error(errorMessage);
  };

  return { isExpired, handleError };
};
