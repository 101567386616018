import React from 'react';
import styles from './styles.module.scss';

const MenuItem = ({ children, onClick, active, type }) => {
  return (
    <div className={`${styles['menu-item']} ${active ? styles.active : ''}`} onClick={onClick}>
      <span className={`${styles['item-icon']} ${styles[type]} ${active ? styles.active : ''}`} />
      {children}
    </div>
  );
};

export default MenuItem;
