import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './ButtonToggle.module.scss';

const ButtonToggle = ({
  disabled = false,
  color = 'grey',
  onClick = () => {},
  isToggled = false,
  children,
}) => (
  <button
    className={classnames(styles['button-toggle'], {
      [styles[`toggle-color-${color}`]]: !!color,
      [styles[`toggled`]]: isToggled,
    })}
    onClick={onClick}
    disabled={disabled}
    type="button"
  >
    {children}
  </button>
);

ButtonToggle.propTypes = {
  className: PropTypes.string,
  isToggled: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['black', 'gray', 'red', 'blue', 'green']),
};

export default ButtonToggle;
