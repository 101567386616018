import React from 'react';
import styles from './SessionHadEndedMessage.module.scss';

const TemporaryProfileUpdateCodeHasExpiredMessage = () => {
  return (
    <div className={styles['welcome-introduction']}>
      <h1>Unfortunately, your temporary profile update code has expired.</h1>
      <p>
        To continue, please go back to your initial invitation email and click on the profile update link. You'll need
        to request a new two-factor authentication code to proceed.
      </p>
    </div>
  );
};

export default TemporaryProfileUpdateCodeHasExpiredMessage;
