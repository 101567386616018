import { _fetch } from 'utils/fetch';

export const editImage = (
  serviceUrl,
  externalToken,
  sharedKey,
  actorId,
  body,
  profileId
) => {
  return _fetch(
    `${serviceUrl}/api/ExternalRegistration/${sharedKey}/Actor/${actorId}/Image/Crop`,
    {
      body,
      method: 'POST',
      external: true,
      externalToken,
      headers: { DatabaseProfileType: profileId }
    }
  );
};

export const deleteImage = (
  serviceUrl,
  externalToken,
  sharedKey,
  actorId,
  actorAttachmentId,
  profileId
) => {
  return _fetch(
    `${serviceUrl}/api/ExternalRegistration/${sharedKey}/Actor/${actorId}/ActorAttachments/${actorAttachmentId}`,
    {
      method: 'DELETE',
      external: true,
      externalToken,
      headers: { DatabaseProfileType: profileId }
    }
  );
};

export const updateSortOrder = (
  serviceUrl,
  externalToken,
  sharedKey,
  actorId,
  body,
  profileId
) => {
  return _fetch(
    `${serviceUrl}/api/ExternalRegistration/${sharedKey}/Actor/${actorId}/Images/SortOrder`,
    {
      body,
      method: 'POST',
      external: true,
      externalToken,
      headers: { DatabaseProfileType: profileId }
    }
  );
};

export const useImageAsThumbnail = (
  serviceUrl,
  externalToken,
  sharedKey,
  actorId,
  actorAttachmentId,
  profileId
) => {
  return _fetch(
    `${serviceUrl}/api/ExternalRegistration/${sharedKey}/Actor/${actorId}/Images/${actorAttachmentId}/DefaultImage`,
    {
      method: 'POST',
      external: true,
      externalToken,
      headers: { DatabaseProfileType: profileId }
    }
  );
};
