import { _fetch } from 'utils/fetch';

export const getProposal = (profileId, messageId, faceId) => {
  return _fetch(`PublicMessages/${profileId}/${messageId}/${faceId}/Proposal`);
};

export const respondProposal = (profileId, messageId, faceId, body) => {
  return _fetch(`PublicMessages/${profileId}/${messageId}/${faceId}/Proposal`, {
    body,
    method: 'POST'
  });
};

export const getAgreement = (profileId, messageId, faceId) => {
  return _fetch(`PublicMessages/${profileId}/${messageId}/${faceId}/Agreement`);
};

export const acceptAgreement = (profileId, messageId, faceId, body) => {
  return _fetch(`PublicMessages/${profileId}/${messageId}/${faceId}/Agreement`, {
    body,
    method: 'POST'
  });
};

export const getProfileDelete = (profileId, messageId, faceId) => {
  return _fetch(`PublicMessages/${profileId}/${messageId}/${faceId}/DeleteProfile`);
};

export const deleteProfile = (profileId, messageId, faceId, body) => {
  return _fetch(`PublicMessages/${profileId}/${messageId}/${faceId}/DeleteProfile`, {
    body,
    method: 'POST'
  });
};
