import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Panel.module.scss';

export const Panel = ({ forMessage, wide, noPadding, children, noBorder }) => (
  <div
    className={cx(styles['panel'], {
      [styles['for-message']]: forMessage,
      [styles['wide']]: wide,
      [styles['no-padding']]: noPadding,
      [styles['no-border']]: noBorder
    })}
  >
    {children}
  </div>
);

Panel.propTypes = {
  children: PropTypes.any,
};

export default Panel;
