import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import Label from 'components/Label';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

import styles from './PhoneInput.module.scss';

const formatNumeber = (value) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parse(value);
    const formatedNumber = phoneUtil.format(parsedNumber, PhoneNumberFormat.E164);
    return { formatedNumber, isValid: true };
  } catch (ex) {
    return { formatedNumber: value, isValid: true, ex };
  }
};

export const PhoneInput = ({ field, placeholder, label, onChange = () => {}, setFieldValue = () => {}, error }) => {
  const [isValid, setIsValid] = useState(true);

  const onValueChange = (valid, value, country) => {
    const isValueDefault = value === country?.dialCode || value === `+${country?.dialCode}`;
    const isValid = !value || valid || isValueDefault;
    setIsValid(isValid);

    if (isValueDefault) {
      setFieldValue(field.name, '', true);
      return;
    }

    if (isValid || !value) {
      if (!value) {
        setFieldValue(field.name, '', true);
        return;
      }

      const formatedNumberResult = formatNumeber(value);
      if (formatedNumberResult.isValid) setFieldValue(field.name, formatedNumberResult.formatedNumber, true);
      else {
        setIsValid(false);
      }
    } else {
      setFieldValue(field.name, value, true);
    }
  };

  const isValidAndNoError = isValid && !error;

  return (
    <div className={styles['phone-container']}>
      <div>{label && <Label error={!isValidAndNoError}>{label}</Label>}</div>
      <IntlTelInput
        containerClassName={classnames('intl-tel-input', [styles['phone-input']], {
          [styles['error']]: !isValidAndNoError
        })}
        nationalMode={false}
        format={true}
        defaultValue={field.value ? formatNumeber(field.value).formatedNumber : '+1'}
        placeholder={placeholder}
        onPhoneNumberBlur={onValueChange}
        preferredCountries={['us', 'ca', 'gb']}
      />
    </div>
  );
};

PhoneInput.propTypes = {
  field: PropTypes.object,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  error: PropTypes.string
};

export default PhoneInput;
