import React from 'react';
import styles from './Text.module.scss';

const Text = ({ children }) => {
  return (
    <div
      className={styles['server-html']}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    />
  );
};

export default Text;
