import React, { useRef, useState, useContext, useEffect } from 'react';
import {useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import Text from 'components/Text';
import Button from 'components/Button';
import ButtonToggle from 'components/ButtonToggle';
import Panel from 'components/Panel';
import AppLoadingBar from 'components/AppLoadingBar';

import { CompanyNameContext } from 'components/CompanyNameContext';

import { getProposal, respondProposal } from 'actions/messages';

import { GLOBAL_ERROR_MESSAGE } from 'utils/constants';

import styles from './Proposal.module.scss';

const TABS = {
  PROPOSAL: 'PROPOSAL',
  SUBMITTED: 'SUBMITTED'
};

const Proposal = () => {
  const [data, setData] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [activeTab, setActiveTab] = useState(TABS.PROPOSAL);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const loadingBarRef = useRef();
  const companyContext = useContext(CompanyNameContext);

  const { params } = useRouteMatch();

  useEffect(() => {
    startLoadingBar();
    getProposal(params.profileId, params.messageId, params.faceId)
      .then((response) => {
        endLoadingBar();
        if (response.payload.Errors && response.payload.Errors.length) {
          const error = response.payload.Errors[0];
          toast.error(error);
          return;
        }

        setData(response.payload.Data);
        companyContext.setCompanyNameAndLogo(response.payload.Data.CompanyName, response.payload.Data.CompanyLogoUrl);
      })
      .catch(() => {
        endLoadingBar();
        toast.error(GLOBAL_ERROR_MESSAGE);
      });
  }, []);

  const startLoadingBar = () => {
    setTimeout(() => {
      loadingBarRef.current.continuousStart();
    }, 100);
  };

  const endLoadingBar = () => {
    setTimeout(() => {
      loadingBarRef.current.complete();
    }, 100);
  };

  const onSubmit = () => {
    if (isSubmiting) {
      return;
    }
    setIsSubmiting(true);
    startLoadingBar();
    respondProposal(params.profileId, params.messageId, params.faceId, { OptionIds: selectedOptions })
      .then((response) => {
        endLoadingBar();
        setIsSubmiting(false);
        if (!response || response.status !== 200) {
          toast.error(GLOBAL_ERROR_MESSAGE);
          return;
        }

        if (response.payload.Errors && response.payload.Errors.length) {
          const error = response.payload.Errors[0];
          toast.error(error);
          return;
        }

        setActiveTab(TABS.SUBMITTED);
      })
      .catch(() => {
        endLoadingBar();
        setIsSubmiting(false);
        toast.error(GLOBAL_ERROR_MESSAGE);
      });
  };

  const onToggle = (value) => () => {
    if (isToggled(value)) {
      setSelectedOptions((prevState) =>
        prevState.filter((val) => val !== value)
      );
    } else {
      setSelectedOptions((prevState) => [...prevState, value]);
    }
  };

  const isToggled = (value) => {
    return selectedOptions.includes(value);
  };

  const renderProposal = () => {
    return (
      <Panel>
        <Text>{data.MessageText}</Text>
        <div className={styles['options']}>
          {data.Options.map((option) => (
            <ButtonToggle
              color={option.Color}
              isToggled={isToggled(option.Value)}
              onClick={onToggle(option.Value)}
            >
              {option.Name}
            </ButtonToggle>
          ))}
        </div>
        <div className={styles['buttons-container']}>
          <Button onClick={onSubmit}>{data.SubmitButton}</Button>
        </div>
      </Panel>
    );
  };

  const renderSubmitted = () => {
    return (
      <Panel forMessage>
        <Text>{data.SubmittedText}</Text>
      </Panel>
    );
  };

  return (
    <>
      {data && (
        <div className={styles['proposal']}>
          {activeTab === TABS.PROPOSAL && renderProposal()}
          {activeTab === TABS.SUBMITTED && renderSubmitted()}
        </div>
      )}
      <AppLoadingBar ref={loadingBarRef} />
    </>
  );
};

export default Proposal;
