import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Label from 'components/Label';

import './NumericInput.scss';

export const NumericInput = ({
  field,
  name,
  placeholder,
  label,
  onChange = () => {},
  value = '',
  step,
  min,
  max,
  error,
  form,
  parentFieldName
}) => (
  <div className="numeric-input-container">
    {label && (
      <Label name={name} error={error}>
        {label}
      </Label>
    )}
    <input
      id={name}
      className={classnames('numeric-input', {
        error: error
      })}
      placeholder={placeholder}
      type="number"
      step={step}
      min={min}
      max={max}
      {...field}
      value={field?.value != null ? field.value : value}
      onChange={(e) => {
        const value = e.target.value;
        onChange(e);
        field.onChange(e);
        if (!value || !value.length) {
          form.setFieldValue(parentFieldName, null, true);
        }
      }}
    />
  </div>
);

NumericInput.propTypes = {
  name: PropTypes.string,
  field: PropTypes.object,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.string,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  error: PropTypes.string
};

export default NumericInput;
