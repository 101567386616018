import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Input from 'components/Input';
import Button from 'components/Button';
import VideoPlayer from 'components/VideoPlayer';

import './VideoUpload.scss';

export const VideoUpload = ({
  name,
  placeholder,
  buttonText = 'Insert',
  onChange = async () => {},
  setFieldValue = () => {},
  validationName,
  videos = [],
  onDelete = async () => {},
  onEdit = async () => {},
  description,
  videoInput = ''
}) => {
  const [stateVideos, setStateVideos] = useState(videos);
  const [stateVideoInput, setStateVideoInput] = useState(videoInput);

  const onInputChange = (event) => {
    setStateVideoInput(event.target.value);
  };

  const onVideosChanged = (videos) => {
    setFieldValue(name, videos);

    if (validationName) setFieldValue(validationName, !!videos.length, true);
  };

  const isAlreadyAdded = (videoUrl) => {
    const videoUrls = stateVideos.map((item) => item.videoUrl);

    if (videoUrls.includes(videoUrl)) {
      toast.error('Video already added');
      return true;
    }

    return false;
  };

  const onAdd = async () => {
    if (!stateVideoInput) {
      return;
    }

    if (!stateVideoInput.includes('youtu') && !stateVideoInput.includes('vimeo')) {
      toast.error('Only youtube and vimeo videos are allowed');
      return;
    }

    if (isAlreadyAdded(stateVideoInput)) return;

    const newVideo = await onChange(stateVideoInput);

    if (!newVideo) {
      return;
    }

    const videos = [...stateVideos, newVideo];
    onVideosChanged(videos);

    setStateVideos(videos);
    setStateVideoInput('');
  };

  const onVideoDelete = async (attachmentId) => {
    await onDelete(attachmentId);
    const videos = stateVideos.filter((item) => item.attachmentId !== attachmentId);
    onVideosChanged(videos);

    setStateVideos(videos);
  };

  const onVideoEdit = async (video, videoUrl) => {
    if (isAlreadyAdded(videoUrl)) return;

    const response = await onEdit(video.attachmentId, videoUrl);
    videos[videos.findIndex((v) => v.attachmentId === response.attachmentId)] = response;
    setStateVideos([...videos]);
  };

  return (
    <div className="video-upload">
      <div className="video-upload-input-container">
        <Input name="video-upload-input" placeholder={placeholder} onChange={onInputChange} value={stateVideoInput} />
        <Button className="video-upload-button" onClick={onAdd}>
          {buttonText}
        </Button>
      </div>

      <VideoPlayer
        videos={stateVideos}
        onDelete={onVideoDelete}
        onEdit={onVideoEdit}
        description={description}
        placeholder={placeholder}
      />
    </div>
  );
};

VideoUpload.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  buttonText: PropTypes.string,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  validationName: PropTypes.func
};

export default VideoUpload;
