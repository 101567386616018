import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Panel from 'components/Panel';
import { CompanyNameContext } from 'components/CompanyNameContext';
import AppLoadingBar from 'components/AppLoadingBar';

import { getUserAgreement } from 'actions/registration';
import { GLOBAL_ERROR_MESSAGE } from 'utils/constants';

import './TermsAndConditions.scss';

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      termsData: null
    };
  }

  startLoadingBar = () => {
    setTimeout(() => {
      this.LoadingBar.continuousStart();
    }, 100);
  };

  endLoadingBar = () => {
    setTimeout(() => {
      this.LoadingBar.complete();
    }, 100);
  };

  componentDidMount() {
    const { match } = this.props;

    this.startLoadingBar();

    getUserAgreement(match.params.sharedKey, match.params.profileId)
      .then(response => {
        this.endLoadingBar();

        if (response && response.status === 200) {
          if (response.payload.Errors && response.payload.Errors.length) {
            var error = response.payload.Errors[0];
            toast.error(error);
          }

          if (response.payload.Successes && response.payload.Successes.length) {
            var success = response.payload.Successes[0];
            toast.success(success);
          }

          this.setState({
            termsData: response.payload.Data
          });
        } else {
          toast.error(GLOBAL_ERROR_MESSAGE);
        }
      })
      .catch(() => {
        this.endLoadingBar();
        toast.error(GLOBAL_ERROR_MESSAGE);
      });
  }

  render() {
    const { termsData } = this.state;
    if (!termsData) {
      return (
        <>
          <AppLoadingBar onRef={ref => (this.LoadingBar = ref)} />
        </>
      );
    }

    return (
      <div className="terms-and-conditions">
        <CompanyNameContext.Consumer>
          {context =>
            context.companyName !== termsData.CompanyName &&
            context.setCompanyNameAndLogo(termsData.CompanyName, termsData.CompanyLogoUrl)
          }
        </CompanyNameContext.Consumer>
        <Panel>
          {termsData.Body && (
            <div
              className="server-html"
              dangerouslySetInnerHTML={{
                __html: termsData.Body
              }}
            />
          )}
        </Panel>
        <AppLoadingBar onRef={ref => (this.LoadingBar = ref)} />
      </div>
    );
  }
}

TermsAndConditions.propTypes = {
  match: PropTypes.object
};

export default TermsAndConditions;
