import React, { useRef, useState, useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import Text from 'components/Text';
import Button from 'components/Button';
import Panel from 'components/Panel';
import AppLoadingBar from 'components/AppLoadingBar';
import DeleteProfile from 'containers/Messages/DeleteProfile';
import { CompanyNameContext } from 'components/CompanyNameContext';

import { getAgreement, acceptAgreement } from 'actions/messages';

import { GLOBAL_ERROR_MESSAGE } from 'utils/constants';

import styles from './Agreement.module.scss';

const TABS = {
  AGREEMENT: 'AGREEMENT',
  REJECT: 'REJECT',
  ACCEPTED: 'ACCEPTED'
};

const Agreement = () => {
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState(TABS.AGREEMENT);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const loadingBarRef = useRef();

  const companyContext = useContext(CompanyNameContext);

  const { params } = useRouteMatch();

  useEffect(() => {
    startLoadingBar();
    getAgreement(params.profileId, params.messageId, params.faceId)
      .then((response) => {
        endLoadingBar();
        if (response.payload.Errors?.length) {
          const error = response.payload.Errors[0];
          toast.error(error);
          return;
        }

        setData(response.payload.Data);
        companyContext.setCompanyNameAndLogo(response.payload.Data.CompanyName, response.payload.Data.CompanyLogoUrl);
      })
      .catch(() => {
        endLoadingBar();
        toast.error(GLOBAL_ERROR_MESSAGE);
      });
  }, []);

  const startLoadingBar = () => {
    setTimeout(() => {
      loadingBarRef.current.continuousStart();
    }, 100);
  };

  const endLoadingBar = () => {
    setTimeout(() => {
      loadingBarRef.current.complete();
    }, 100);
  };

  const onAccept = () => {
    if (isSubmiting) {
      return;
    }

    setIsSubmiting(true);

    startLoadingBar();
    acceptAgreement(params.profileId, params.messageId, params.faceId, {
      OptionIds: [data.AcceptButtonId]
    })
      .then((response) => {
        endLoadingBar();
        setIsSubmiting(false);
        if (!response || response.status !== 200) {
          toast.error(GLOBAL_ERROR_MESSAGE);
          return;
        }

        if (response.payload.Errors && response.payload.Errors.length) {
          const error = response.payload.Errors[0];
          toast.error(error);
          return;
        }

        setActiveTab(TABS.ACCEPTED);
      })
      .catch(() => {
        endLoadingBar();
        setIsSubmiting(false);
        toast.error(GLOBAL_ERROR_MESSAGE);
      });
  };

  const onReject = () => {
    setActiveTab(TABS.REJECT);
  };

  const onReconsider = () => {
    setActiveTab(TABS.AGREEMENT);
  };

  const renderAgreement = () => {
    return (
      <Panel wide>
        <Panel wide noPadding>
          <Text>{data.AgreementText}</Text>
        </Panel>
        <div className={styles['buttons-container']}>
          <div className={styles['buttons-centered']}>
            <Button onClick={onAccept}>{data.AcceptButton}</Button>
            <Button danger onClick={onReject}>
              {data.RejectButton}
            </Button>
          </div>
        </div>
      </Panel>
    );
  };

  const renderAccepted = () => {
    return (
      <Panel forMessage>
        <Text>{data.AcceptedText}</Text>
      </Panel>
    );
  };

  const renderReject = () => {
    return (
      <DeleteProfile
        onReconsider={onReconsider}
        profileDeleteData={data.ProfileDelete}
        deleteAction={acceptAgreement}
      />
    );
  };

  return (
    <>
      {data && (
        <div className={styles['agreement']}>
          {activeTab === TABS.AGREEMENT && renderAgreement()}
          {activeTab === TABS.ACCEPTED && renderAccepted()}
          {activeTab === TABS.REJECT && renderReject()}
        </div>
      )}
      <AppLoadingBar ref={loadingBarRef} />
    </>
  );
};

export default Agreement;
