import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Label from 'components/Label';

import Styles from './CheckboxMulti.module.scss';

export const CheckboxMulti = ({
  field,
  setFieldValue = () => {},
  name,
  label,
  error,
  items = [],
  singleCheckBox = false
}) => {
  const onClick = (item, selectedIndex) => () => {
    if (singleCheckBox) {
      const previouslySelectedItem = items.find((item, index) => item.isSelected && index !== selectedIndex);
      if (previouslySelectedItem) {
        const previouslySelectedIndex = items.indexOf(previouslySelectedItem);
        setFieldValue(
          `${field.name}.${previouslySelectedIndex}`,
          { ...previouslySelectedItem, isSelected: false },
          true
        );
      }
      setFieldValue(`${field.name}.${selectedIndex}`, { ...item, isSelected: true }, true);
    } else {
      setFieldValue(`${field.name}.${selectedIndex}`, { ...item, isSelected: !item.isSelected }, true);
    }
  };
  return (
    <div className={Styles['checkbox-multi-container']}>
      {label && (
        <Label name={name} error={error}>
          {label}
        </Label>
      )}
      <div
        className={cx(Styles['checkbox-multi-component'], {
          [Styles['errors']]: !!error
        })}
      >
        <div className={Styles['check-box-wrapper']}>
          <ul>
            {items
              .filter((item) => item.id !== 0)
              .map((item, index) => (
                <li key={`${item.id}_${index}`} className={Styles['checkbox-list-item']} onClick={onClick(item, index)}>
                  <div className={Styles['row']}>
                    <div className={Styles['col-sm-2']}>
                      <div
                        className={cx({
                          [Styles['button-notaddedtoproject']]: !item.isSelected,
                          [Styles['button-addedtoproject']]: item.isSelected
                        })}
                      ></div>
                    </div>
                    <div className={Styles['col-sm-9']}>
                      <div className={Styles['name-outer']}>
                        <div className={Styles['name-inner']}>{item.name}</div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

CheckboxMulti.propTypes = {
  setFieldValue: PropTypes.func
};

export default CheckboxMulti;
