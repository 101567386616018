import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Label from 'components/Label';

import './Textarea.scss';

export const Textarea = ({
  field,
  name,
  placeholder,
  label,
  onChange = () => {},
  value = '',
  error
}) => (
  <div className="textarea-container">
    {label && <Label name={name} error={error}>{label}</Label>}
    <textarea
      id={name}
      className={classnames('textarea', {
        error: error
      })}
      placeholder={placeholder}
      onChange={onChange}
      {...field}
      value={field && field.value ? field.value : value}
    />
  </div>
);

Textarea.propTypes = {
  field: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.string
};

export default Textarea;
